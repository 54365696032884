






















import { Component, Vue } from "vue-property-decorator"
import Combobox from "@/components/inputs/Combobox.vue"
import GlobalIndicatorsModule from "@/store/modules/GlobalIndicatorsModule"
import { YEARS_FROM } from "@/model/years"

@Component({
  components: {
    Combobox
  }
})
export default class GlobalIndicatorsToolbar extends Vue {
  get years(): number[] {
    return YEARS_FROM(2021)
  }
  get selectedYears(): number | null {
    return GlobalIndicatorsModule.selectedYear
  }

  set selectedYears(year: number | null) {
    GlobalIndicatorsModule.updateSelectedYear(year)
  }

  get regionsList(): string[] | undefined {
    return GlobalIndicatorsModule.dataset?.regionsList
  }

  get selectedRegion(): string | null {
    return GlobalIndicatorsModule.selectedRegion
  }

  set selectedRegion(region: string | null) {
    GlobalIndicatorsModule.updateSelectedRegion(region)
  }

  get countriesList(): string[] | undefined {
    return GlobalIndicatorsModule.dataset?.countriesList
  }

  get selectedCountry(): string | null {
    return GlobalIndicatorsModule.selectedCountry
  }

  set selectedCountry(country: string | null) {
    GlobalIndicatorsModule.updateSelectedCountry(country)
  }
}
